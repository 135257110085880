$icon-font-path: "../fonts/" !default;
$btn-default-bg: #ddd !default;
@import '../bower_components/bootstrap-sass/assets/stylesheets/bootstrap';

@media screen and (min-width:1200px) {
	.container {
		max-width: 1200px !important;
	}
}

.container {
	@viewport {
		width: 100%;
		zoom: 1;
	}
}

.container {
	width: 100%;
	&.main {
		margin-top: 80px;
	}
}

.redactor-content {
	.container.main {
		-webkit-user-select: inherit !important;
		-webkit-user-drag: inherit !important;
	}
}

#profile-text {
	display: none;
}

.navbar, .header {
	margin-bottom: 0;
}

.navbar {
	position: fixed ;
	width: 100%;
	top: 0px;
	z-index: 100;
	transition: top 0.2s ease-in-out;
	.nav-up {
		top: -40px;
	}
}

.input-group {
	width: 100%;
	input {
		font-weight: normal;
	}
}
input::placeholder {
	font-weight: 400;
}

.table-container {
	border-bottom: dotted 2px #fafafa;
	table {
		margin-bottom: 0px;
	}
	.select-amount {
		margin-bottom: -30px;
	}
}

@media screen and (max-width:768px) {
	#profile-image {
		display: none;
	}
	#profile-text {
		display: block;
	}
	h1 {
		font-size: 24px
	}
	h2 {
		font-size: 22px
	}
	h3 {
		font-size: 18px;
		margin-top: 15px;
	}
	.jumbotron {
		width: 100%;
		background-color: #fff;
		border-bottom: none !important;
	}
	.navigation.header {
		position: fixed;
		width: 100%;
		z-index: 100;
		padding: 0px;
	}
	.container.main {
		padding-top: 50px;
		overflow: auto;
		margin-top: 0px;
	}
	.edit-modal.show {
		width: 100%;
		padding: 10px;
		.head {
			width: 100%;
			.pull-right {
				float: left !important;
				margin: 0 0 10px 0 !important;
			}
		}
	}

	.edit-modal.show, .table-container, .profile form {
		.row {
			display: table;
			margin-left: -5px;
			margin-right: -5px;
		}
		.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
			padding-left: 5px;
			padding-right: 5px
		}
		textarea {
			height: 80px;
		}
	}
	.shopping .well {
		padding: 15px 0;
		.finished {
			position: relative;
			margin: 0 15px 5px 0;
			bottom: 0px;
			right: 0px;
		}
		#order-list {
			border-radius: 0px;
			margin-top: 10px;
			margin-bottom: 10px;
			.row {
				display: inherit !important;
			}
		}
	}
}

body {
	display: none;
	&.ng-scope {
		display: inherit;
	}
}

.modal-wrapper {
	width: 100%;
	overflow: hidden;
	position: absolute;
	height: 100%;
	top: 0px;
	left: 0px;
}
#profile-image {
	padding: 10px;
	img {
		width: 30px;
		display: block;
	}
}

.jumbotron .btn {
	font-size: 14px !important;
	width: 100%;
	margin-bottom: 5px;
	white-space: normal;
	vertical-align: middle;
}

.fa {
	&.bio-admin:before {
		content: '\f0c0';
	}
	&.bio-purchase:before {
		content: '\f291';
	}
	&.bio-unpack:before {
		content: '\f16b';
	}
	&.bio-caretaker:before {
		content: '\f015';
	}
	&.bio-web:before {
		content: '\f109';
	}
	&.bio-finance:before {
		content: '\f0d6';
	}
	&.bio-board:before {
		content: '\f2be'
	}
	&.bio-orga:before {
		content: '\f073'
	}
	&.bio-fresh:before {
		content: '\f299'
	}
	&.bio-receiver:before {
		content: '\f0d1'
	}
	&.bio-doorcoder:before {
		content: '\f023'
	}
	&.bio-wood:before {
		content: '\f06d'
	}
	&.bio-inventory:before {
		content: '\f2bc'
	}
}

.edit-modal {
	&.account-info {
		width: 80%;
		right: -80%;
		.head {
			width: 80%;
		}
	}
	width: 60%;
	position: fixed;
	height: 100%;
	overflow-y: auto;
	right: -60%;
	background-color: #fff;
	top: 0px;
	padding: 30px;
	border-left: solid 1px #DDD;
	transition: right .35s cubic-bezier(0.215, 0.610, 0.355, 1.000);
	&.show {
		right: 0;
	}
	z-index: 1000;
	.member-info {
		.row {
			margin-bottom: 20px;
		}
	}
	.head {
		position: fixed;
		top: 0px;
		background-color: #fff;
		z-index: 5;
		border-bottom: 1px solid #EEE;
		width: 60%;
		margin-left: -30px;
		padding: 0px 30px;
	}
}

.edit-modal.account-info .edit-modal {
	.head {
		width: 60%;
	}
}


.browsehappy {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}

body {
	padding: 0;
}

.row {
	display: flex;
}

.button-action {
	border-radius: 50% 50%;
	width: 100px;
	height: 100px;
	display: block;
	margin-top: 60px;
	border: solid 3px #eeeeee;
	transition: .3s border;
	-moz-transition: .3s border;
	-webkit-transition: .3s border;
	-o-transition: .3s border;
	&:hover {
		border: solid 3px #fff;
	}
	img {
		width: 100%;
	}
}

.table-container h2 {
	text-transform: capitalize;
}
.table tbody tr .ng-scope {
	cursor: default;
	user-select: text;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
	padding-left: 15px;
	padding-right: 15px;
}

/* Custom page header */
.header {

	/* Make the masthead heading the same height as the navigation */
	h3 {
		margin-top: 0;
		margin-bottom: 0;
		line-height: 40px;
		padding-bottom: 19px;
	}
}

/* Custom page footer */
.footer {
	padding-top: 19px;
	color: #777;
	border-top: 1px solid #e5e5e5;
}

.container-narrow > hr {
	margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
	text-align: center;
	border-bottom: 1px solid #e5e5e5;

	.btn {
		font-size: 21px;
		padding: 14px 24px;
	}
	.img-btn {
		width: 45%;
		max-width: 150px;
		border: 2px solid #eeeeee;
		border-radius: 50%;
		overflow: hidden;
		padding: 2px;
		display: inline-block;
		&:hover {
			border: 2px solid #777;
		}
		img {
			width: 100%;
		}
	}
}

/* Supporting marketing content */
.marketing {
	margin: 40px 0;

	p + h4 {
		margin-top: 28px;
	}
}

.group-membership {
	white-space: nowrap;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
	.container {
		max-width: 900px;
	}

	/* Remove the padding we set earlier */
	.header,
	.marketing,
	.footer {
		padding-left: 0;
		padding-right: 0;
	}
	/* Space out the masthead */
	.header {
		margin-bottom: 30px;
	}
	/* Remove the bottom border on the jumbotron for visual effect */
	.jumbotron {
		border-bottom: 0;
	}
}

.form-control.date {
	font-size: .9em;
	padding: 5px 4px;
}

.groups table tr.leader td {
	font-weight: bold;
}

.popover:focus {
    outline-style: none;
}

td.st-sort-ascent:after, td.st-sort-descent:after {
	content: '';
}

td.tools {
	.input-group {
		margin-top: 2px;
	}
}

.popover .form-actions {
	margin: 0 -14px -29px;
	padding: 9px 10px 10px;
	border-radius: 0 0 5px 5px;
	margin-top: 20px;
	margin-bottom: 0px;
	background-color: #f5f5f5;
	border-top: 1px solid #e5e5e5;
	zoom: 1;
	:before {
		display: table;
		line-height: 0;
		content: ""
	}
	:after {
		clear: both
	}

}


.tooltipped {
	&:after {
		position: absolute;
		z-index: 1000000;
		padding: 5px 8px;
		font-size: 11px;
		color: #666;
		text-align: center;
		text-decoration: none;
		text-shadow: none;
		text-transform: none;
		letter-spacing: normal;
		word-wrap: break-word;
		white-space: pre;
		pointer-events: none;
		content: attr(aria-label);
		background: rgba(#fff, 0.5);
		border-radius: 3px;
		border: solid 1px #AAA;
		-webkit-font-smoothing: subpixel-antialiased;
		top: 100%;
		right: 50%;
		margin-top: 5px;
		-webkit-transform: translateX(50%);
		-ms-transform: translateX(50%);
		transform: translateX(50%)
	}
	&:before {
		position: absolute;
		z-index: 1000001;
		width: 0;
		height: 0;
		color: #AAA;
		pointer-events: none;
		content: "";
		border: 5px solid transparent;
		top: auto;
		right: 50%;
		bottom: -5px;
		margin-right: -5px;
		border-bottom-color: #AAA;
	}
}


.redactor-toolbar {
	background: #f8f8f8 !important;
	z-index: 10;
}
.redactor-air li a.re-button-icon, .redactor-toolbar li a.re-button-icon {
	padding: 10px;
}
.redactor-air li a, .redactor-toolbar li a {
	height: inherit;
}

.redactor-in {
	padding: 24px;
	border: 1px dashed rgba(0, 0, 0, .15);
}
.redactor-layer {
	font-size: 14px;
	h1, h2, h3, h4 {
		font-weight: normal;
	}
	h1 {
		font-size: 24px;
	}
	h2 {
		font-size: 20px;
	}
}

.re-save:before {
	font-family: "FontAwesome";
	content: "\f0c7";
}

td.tools {
	min-width: 100px;
}

.multiselect-parent .dropdown-header {
	font-weight:bold;
	color: #322;
	text-transform: uppercase;
}
