@import '../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';

.shopping .row {
	display: inherit;
}
.row-fluid {
	width: 100%;
}
#order-list {
	border-radius: 5px;
	background-color: #fff;
	margin-bottom: 60px;
	li {
		padding-right: 4px;
		list-style: none;
		font-weight: 200;
		&.header {
			font-size: 16px;
			margin-bottom: 0px;
		}
		&:not(.header) {
			text-align: right;
		}
	}
}
.shopping .well {
	position: relative;
	.finished {
		position: absolute;
		bottom: 20px;
		right: 30px;
		font-size: 1.2em !important;
		height: 50px;
		margin: 0 5px 5px 0;
		line-height: 36px;
		font-weight: bold;
	}
}
#calculator-screen {
	height: 42px;
	font-size: 42px;
	line-height: 35px;
	width: 100%;
	background-color: #fdfdfd;
	border: solid 1px #DDD;
	margin-bottom: 20px;
	padding: 2px 10px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	text-align: right;
}

#calc-board {
	.btn, .save-btn {
		&:last-child {
			margin-right: 0px !important;
		}
		height: 50px;
		margin: 0 5px 5px 0;
		line-height: 36px;
		font-weight: bold;
		font-size: 25px;
		&:not(.save-btn) {
			width: calc(25% - 7px);
		}
		&.big-btn {
			width: calc(37% - 4px)
		}
	}

	.btn-success:not(.finished), .btn-primary {
		font-size: 1.2em !important;
	}
}

.badge {
// 	width: 22px;
	font-size: 9px;
	height: 16px;
	margin-top: -2px;
	opacity: .4;
	&.fa-check {
		background-color: $brand-success;
	}
	&.fa-question {
		background-color: $brand-warning;
	}
	&.fa-remove {
		background-color: $brand-danger;
	}
}

.state {
	.fa-times-circle {
		color: darken($brand-danger, 20%);
	}
	.fa-circle {
		color: darken($brand-success, 20%);
	}
}
#calc-board .finished {
	width: 98% !important;
}
.st-sort-ascent:after {
	content: '\25B2';
}

.st-sort-descent:after {
	content: '\25BC';
}
