.editInfos .title {
  margin-bottom: 10px; }

.infos .info {
  color: #666;
  border: solid 1px #FAFAFA;
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 15px; }
  .infos .info .author {
    display: none; }
    .infos .info .author a {
      color: #666; }
    .infos .info .author::before {
      content: ' von '; }
  .infos .info .title {
    font-size: 24px; }
