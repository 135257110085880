.networking .redactor-box {
  margin-bottom: 20px; }

.networking .filter {
  margin-bottom: 30px; }

.networking .row {
  display: block; }
  .networking .row [class*="col-"] {
    margin-bottom: 15px; }
    .networking .row [class*="col-"] .content {
      padding: 12px 10px;
      background-color: #FAFAFA; }
    .networking .row [class*="col-"] .author {
      font-weight: bold; }
      .networking .row [class*="col-"] .author .info {
        display: none; }
        .networking .row [class*="col-"] .author .info a {
          color: #333; }
        .networking .row [class*="col-"] .author .info .phone {
          white-space: nowrap; }
    .networking .row [class*="col-"] p {
      margin-bottom: 0px; }
    .networking .row [class*="col-"]:hover .info {
      display: inline-block; }

@media screen and (max-width: 768px) {
  [class*="col-"] .info {
    display: inline-block !important; } }
