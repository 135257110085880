.doorcode {
  position: relative; }
  .doorcode input {
    position: absolute;
    z-index: 10;
    width: 80px;
    margin-left: 30px;
    margin-top: 125px;
    text-align: center; }
  .doorcode .fa-lock {
    color: #FFCD5C;
    font-size: 220px;
    left: 0px;
    z-index: 9; }
