@import '../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables';

body.website {
//  background-color: #b0420a;
  background-image: url(/images/background.jpeg);
	.middle-box {
		&.big {
			width: 700px;
			top: inherit;
			margin-left: -350px;
			margin-top: 100px;
			margin-bottom: 30px;
			.logo {
				margin-top: -80px;
				margin-left: -125px;
				left: 50%;
				position: relative;
			}
			.subtitle {
				font-weight: 300;
				padding-bottom: 30px;
			}
		}
	  width: 300px;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  margin-top: -150px;
	  margin-left: -150px;
	  z-index: 100;
	  background-color: #fff;
	  padding: 20px;
	  border-radius: 5px;
	}

	.logo {
		margin: -80px auto 0 auto;
	}
	.logo-name {
	  color: #e6e6e6;
	  font-size: 180px;
	  font-weight: 800;
	  letter-spacing: -10px;
	  margin-bottom: 0;
	}
}
