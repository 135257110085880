.document-row {
  display: block; }

.documents .list .item {
  position: relative;
  width: 100%;
  border-bottom: dotted 1px #d0d0d0;
  cursor: pointer;
  margin-bottom: 3px; }
  .documents .list .item .delete, .documents .list .item .copy-link {
    opacity: 0;
    transition: opacity .5s;
    color: #fff;
    padding: 5px;
    top: 0px;
    position: absolute; }
  .documents .list .item .copy-link {
    background-color: #31acff;
    right: 20px; }
    .documents .list .item .copy-link:hover {
      background-color: #0491ff !important; }
  .documents .list .item .delete {
    background-color: #ff5b64;
    right: 0px; }
    .documents .list .item .delete:hover {
      background-color: #ff0000 !important; }
  .documents .list .item .info {
    color: #888;
    display: inline-block;
    margin-left: 20px; }
    .documents .list .item .info::before {
      content: '( '; }
    .documents .list .item .info::after {
      content: ')'; }
    .documents .list .item .info .author {
      display: none; }
      .documents .list .item .info .author::before {
        content: ' von ';
        color: #BBB; }
      .documents .list .item .info .author::after {
        color: #BBB;
        content: ' bearbeitet'; }
  .documents .list .item .document-title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100% - 15px);
    display: inline-block;
    padding: 5px 0;
    font-size: 1.2em; }
  .documents .list .item:hover .delete, .documents .list .item:hover .info .author, .documents .list .item:hover .copy-link {
    opacity: 1;
    display: inline-block; }
  .documents .list .item.active .document-title {
    margin-left: 15px; }
  .documents .list .item.active:before {
    content: '> ';
    margin-top: 7px;
    position: absolute; }

.documents .document {
  color: #666; }
  .documents .document .info {
    text-align: center;
    margin-bottom: 20px; }
    .documents .document .info .author a {
      color: #666; }
    .documents .document .info .author::before {
      content: ' von '; }
  .documents .document .form-control.title {
    font-size: 24px;
    padding: 0px;
    border: none;
    box-shadow: none !important;
    margin-bottom: 10px;
    text-align: center; }
