.imprint .row, .about .row, .recent .row {
  display: block; }

.imprint textarea, .about textarea, .recent textarea {
  margin-top: 5px;
  margin-bottom: 5px;
  height: auto;
  padding: 10px 12px;
  width: 100%;
  min-height: 250px; }
  .imprint textarea:focus, .about textarea:focus, .recent textarea:focus {
    outline: none; }
