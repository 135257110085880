.payment-status-info {
  color: #c7254e; }

.payment-status-2 {
  color: #4D86B2; }

.payment-status-3 {
  color: #c7254e; }

.addTransaction.row {
  display: block; }

#account .row {
  display: block; }
  #account .row:not(:last-child) {
    border-bottom: solid 1px #EEE;
    padding-bottom: 15px; }

input.danger {
  border-color: #C99593;
  background-color: #fceceb; }
