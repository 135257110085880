.recent .row, .editInfos .row, .networking .row {
  display: block; }

.recent .title, .editInfos .title, .networking .title {
  margin-bottom: 10px; }

.recent .post, .editInfos .post, .networking .post {
  color: #333;
  border: solid 1px #FAFAFA;
  border-radius: 3px;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 15px; }
  .recent .post .action, .editInfos .post .action, .networking .post .action {
    float: right;
    margin-top: -30px;
    margin-right: -20px; }
    .recent .post .action .delete, .recent .post .action .edit, .editInfos .post .action .delete, .editInfos .post .action .edit, .networking .post .action .delete, .networking .post .action .edit {
      float: right;
      border-radius: 50%;
      border: solid 1px #aaa;
      background-color: #fafafa;
      display: block;
      padding: 10px;
      height: 50px;
      width: 50px;
      font-size: 20px;
      text-align: center;
      color: #777;
      margin-left: 5px;
      transition: all .2s; }
      .recent .post .action .delete:hover, .recent .post .action .edit:hover, .editInfos .post .action .delete:hover, .editInfos .post .action .edit:hover, .networking .post .action .delete:hover, .networking .post .action .edit:hover {
        cursor: pointer; }
    .recent .post .action .delete:hover, .editInfos .post .action .delete:hover, .networking .post .action .delete:hover {
      background-color: #fae2e5;
      color: #faa09d;
      border-color: #faa09d; }
    .recent .post .action .edit:hover, .editInfos .post .action .edit:hover, .networking .post .action .edit:hover {
      background-color: #edfaf1;
      color: #89bb96;
      border-color: #89bb96; }
  .recent .post .info, .editInfos .post .info, .networking .post .info {
    margin-bottom: 5px; }
    .recent .post .info .time::before, .editInfos .post .info .time::before, .networking .post .info .time::before {
      content: ' ('; }
    .recent .post .info .time::after, .editInfos .post .info .time::after, .networking .post .info .time::after {
      content: ') '; }
    .recent .post .info .author a, .editInfos .post .info .author a, .networking .post .info .author a {
      color: #777; }
    .recent .post .info .author::before, .editInfos .post .info .author::before, .networking .post .info .author::before {
      content: ' / '; }
  .recent .post .title, .editInfos .post .title, .networking .post .title {
    font-size: 24px; }
  .recent .post.current, .editInfos .post.current, .networking .post.current {
    opacity: .5; }

@media screen and (max-width: 768px) {
  .recent .action, .editInfos .action, .networking .action {
    margin-right: -10px !important; } }
